<template>
  <div class="site-header-box">
    <div class="advertisement top-ad" v-if="modelAgShow">
      <img class="close" v-if="isScrolledHeight > 0" src="../assets/image/close-bg.png" alt=""
        @click="modelAgShow = false">
      <img class="ad-img" :style="{ height: isScrolledHeight + 'px' }" :src="modelAgArr[0]?.pictureUrl" alt=""
        @click="goAd(modelAgArr[0]?.linkUrl)">
    </div>
    <div class="site-header">
      <div class="logo">
        <logo></logo>
      </div>
      <div class="head-menu">
        <div class="menu-item">
          <router-link to="/index">首页</router-link>
        </div>
        <div class="menu-item">
          <router-link to="/latest">最新推荐</router-link>
        </div>
        <div class="menu-item">
          <router-link to="/recommend">厂牌推荐</router-link>
        </div>
        <div class="menu-item">
          <router-link to="/uploadInt">
            <a-badge count="0佣金">
              开店赚钱
            </a-badge>
          </router-link>
        </div>
      </div>
      <div class="head-recharge-btn" @click="goPage('/recharge/price')">
        <img src="../assets/image/zuanshi.png" alt=""><span>充值送现金</span>
        <div class="version">V2.0.2</div>
      </div>
      <div class="header-right">
        <div class="search-head showSearchInput">
          <diy-select class="diy-select" v-model="searchCategory"></diy-select>
          <a-input class="search-input" v-model:value="searchText" placeholder="" @keydown.enter="keyDown">
            <template #suffix>
              <!-- <SearchOutlined style="color: #00B96B;cursor: pointer;" @click="goSearch()" /> -->
              <img style="width:22px;height:22px;cursor: pointer;" src="https://pubimg-wfile.xiangsusheji.com/2024/04/11/14/07f2af4a-b519-46fb-932a-8798a0614837.png" alt="" @click="goSearch()">
            </template>
          </a-input>
        </div>
        <div class="hideSearchInput">
          <img src="../assets/image/search.png" alt="" @click="goMobileSearch()">
        </div>
        <div class="avatar">
          <website-avatar-select></website-avatar-select>
        </div>
        <div class="menu-btn" @click="isOpen = !isOpen">
          <img v-if="isOpen" src="../assets/image/close.png" alt="">
          <img v-else src="../assets/image/menu-btn.png" alt="">
        </div>
      </div>
      <!-- 移动端导航样式 -->
      <div v-if="isOpen" class="small-menu">
        <div v-if="store.state.userInfo?.avatar"
          style="margin-bottom: 20px;display: flex;justify-content: space-between;">
          <div class="list-head">
            <img class="small-avatar" :src="store.state.userInfo?.avatar" alt="">
            <div class="user-info ml-10">
              <div class="name c0 fs-15 fw-500">{{ store.state.userInfo?.realname }}</div>
              <div class="tag c-00B96B fs-10" v-if="store.state.userInfo?.brandTitle">{{
      store.state.userInfo?.brandTitle }}
              </div>
            </div>
          </div>
          <div class="topup">
            <div class="unit c0">
              <span class="c9 mr-10 fs-10">余额</span>
              <span class="price fw-bold fs-24">{{ store.state.myBalance?.rechargeAmount }}</span>
            </div>
            <a-button type="primary" class="fs-13 ml-20" size="small" @click="goPage('/recharge/price')"
              style="min-width: 57px;height: 28px;">充值</a-button>
          </div>
        </div>
        <a-button v-else style="width: 100%;margin-bottom: 20px;" type="primary"
          @click="goPage('/login')">登录/注册</a-button>
        <div style="display:flex;justify-content: space-between;margin-bottom: 20px;">
          <div class="menu-bg-btn" @click="goPage('/my/home')">开店赚钱</div>
          <div class="menu-bg-btn blk-btn" @click="goPage('/recharge/price')"><img style="margin-top:-5px"
              src="../assets/image/zuanshi.png" /> 充值送现金</div>
        </div>
        <a-menu v-model:selectedKeys="current" mode="inline" @click="menuClick">
          <a-menu-item key="home">
            <router-link to="/index">首页</router-link>
          </a-menu-item>
          <a-menu-item key="last">
            <router-link to="/latest">最新推荐</router-link>
          </a-menu-item>
          <a-menu-item key="brand">
            <router-link to="/recommend">厂牌推荐</router-link>
          </a-menu-item>
          <a-menu-item key="openShop">
            <router-link to="/uploadInt">开店赚钱</router-link>
          </a-menu-item>
          <a-sub-menu key="my">
            <template #title>个人中心</template>
            <!-- <a-menu-item key="myhome"><router-link to="/my/home">面板首页</router-link></a-menu-item> -->
            <!-- <a-menu-item key="works"><router-link to="/my/works">我的作品</router-link></a-menu-item> -->
            <!-- <a-menu-item key="earnings"><router-link to="/my/earnings">我的收益</router-link></a-menu-item> -->
            <a-menu-item key="download"><router-link to="/my/download">我的下载</router-link></a-menu-item>
            <!-- <a-menu-item key="payment"><router-link to="/my/payment">我的支付</router-link></a-menu-item> -->
            <a-menu-item key="collect"><router-link to="/my/collect">我的收藏</router-link></a-menu-item>
            <a-menu-item key="setting"><router-link to="/my/setting">账号设置</router-link></a-menu-item>
            <a-menu-item v-if="store.state.userInfo?.avatar" key="out" @click="logout">退出账号</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <a-modal v-model:visible="showConfirm" title="确认退出账号吗" okText="确认" cancelText="取消" :closable="false"
        @ok="handleOk">
      </a-modal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import logo from "@/components/logo/index.vue"; // @ is an alias to /src
import diySelect from "@/components/diy-select/index.vue"; // @ is an alias to /src
import websiteAvatarSelect from "@/components/website-avatar-select/index.vue";
import { store } from "@/store/index"
import { UserOutlined, SearchOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, onUnmounted, watch } from "vue";
import request from '@/utils/request';

import { useRouter, useRoute } from 'vue-router'
import { message } from "ant-design-vue";
if (localStorage.getItem('token')) {
  store.dispatch("getBalance")
}
const router = useRouter()
const route = useRoute()
const current = ref(['home']);
// 搜索文字
const searchText = ref<string>(route.query.searchText || '');
const searchCategory = ref<string>(route.query.pid || '');
// watch(() => searchCategory.value, (nv, ov) => {
//   if (nv) {
//     goSearch()
//   }
// })
//点击回车键搜索
const keyDown = (e: any) => {
  goSearch()
}
const goMobileSearch = () => {
  router.push({
    path: '/search/mobile',
  })
}
const goSearch = () => {

  // if (route.path == '/filtering') {
  //   if (searchCategory.value) {
  //     router.push({
  //       path: route.path,
  //       query: {
  //         ...route.query,
  //         searchText: searchText.value,
  //         pid: searchCategory.value
  //       }
  //     })
  //   } else {
  //     router.push({
  //       path: route.path,
  //       query: {
  //         ...route.query,
  //         searchText: searchText.value
  //       }
  //     })
  //   }

  // } else {
  //   router.push({
  //     path: '/search',
  //     query: {
  //       searchText: searchText.value
  //     }
  //   })
  // }
  // return
  searchCategory.value = route.query.pid || store.state.categoryType.value
  if (searchCategory.value) {
    router.push({
      path: '/filtering',
      query: {
        ...route.query,
        id: route.query.id || '',
        pid: searchCategory.value,
        searchText: searchText.value
      }
    })
  } else {
    if (!searchText.value) {
      message.error('请输入要搜索的关键词');
      return
    }
    router.push({
      path: '/search',
      query: {
        searchText: searchText.value
      }
    })
  }
}
const goPage = (url: any) => {
  isOpen.value = false
  router.push({
    path: url,
    query: {}
  })
}
const isOpen = ref<boolean>(false);
const menuClick = (e: any) => {
  console.log(e);
  isOpen.value = false
}
const showConfirm = ref(false);
const logout = (e: any) => {
  showConfirm.value = true
}
const handleOk = () => {
  request.post('/login/logout').then(res => {
    if (res && res.code === 200) {
      router.push({ path: "login" })
      localStorage.clear();
      store.commit("setUserInfo", null)
    }
  });
}
// 顶部弹框
const modelAgArr = ref<any[]>([])
const modelAgShow = ref<Boolean>(false)
request.get("/home/getAdvList", { type: 1 }).then((res: any) => {
  if (res && res.code == 200) {
    modelAgArr.value = res.result
    if (res.result && res.result.length > 0) {
      modelAgShow.value = true
    }
  }
})
const goAd = (url: any) => {
  window.open(url)
}


const isScrolledToTop = ref(true);
const isScrolledHeight = ref(0)

const handleScroll = () => {
  isScrolledToTop.value = window.scrollY === 0;
  isScrolledHeight.value = isScrolledToTop.value ? 60 : 0
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  handleScroll()
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="less">
.site-header-box {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
}

.top-ad {
  width: 100%;
  position: relative;

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 1;
    width: 24px;
    cursor: pointer;
  }
}

.site-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0px 50px;


  * {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    // background: url('https://pubimg-wfile.xiangsusheji.com/2024/04/13/23/e0e15224-9fda-45de-a1e9-e81beacfc281.png') center center;
    /* 高斯模糊的半径，单位是像素 */
    z-index: -1;
  }

}

.head-menu {
  margin-left: 70px;
  display: flex;
  align-items: center;
  height: 100%;

  .menu-item {
    margin: 0px 25px;
    height: 100%;

    a {
      font-size: 15px;
      color: #666;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;


      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 1;
        width: 0%;
        height: 3px;
        border-radius: 2px;
        background: #00B96B;
        transition: all 0.3s;
      }

      &.router-link-exact-active {
        color: #00B96B;

        .ant-badge {
          color: #00B96B;
        }

        &::after {
          width: 90%;
        }
      }

      &:hover {
        color: #00B96B;
        font-weight: 500;

        .ant-badge {
          color: #00B96B;
        }
      }
    }
  }
}

.head-recharge-btn {
  display: flex;
  align-items: center;
  background-color: #000;
  border-radius: 25px;
  font-size: 15px;

  display: flex;
  align-items: center;
  padding: 10px 17px 8px 15px;
  cursor: pointer;
  margin-left: 85px;
  position: relative;
  line-height: 26px;
  overflow: hidden;

  img {
    width: 26px;
  }

  span {
    color: #fff;
  }

  &:hover {
    opacity: .9;
  }

  .version {
    position: absolute;
    left: 110%;
    top: 14px;
    color: #000;
    font-size: 14px;
    z-index: 1;
  }
}

.head-recharge-btn::before {
  content: '';
  position: absolute;
  top: -10%;
  left: -150%;
  width: 20px;
  height: 120%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  transform: rotate(15deg);
  animation: sweep 2s infinite linear;
}

@keyframes sweep {
  0% {
    left: -150%;
  }

  100% {
    left: 100%;
  }
}

.header-right {
  margin-left: auto;
  display: flex;
  align-items: center;

  .search-head {
    // display: flex;
    // align-items: center;
    // background: #F5F7FB;
    border-radius: 6px;
    margin-right: 25px;
    position: relative;

    ::v-deep .diy-select {
      position: absolute;
      z-index: 9;
      left: 0px;
      top: 50%;
      margin-top: -12px;
    }

    ::v-deep .ant-input {
      text-indent: 105px !important;
      background-color: rgba(0, 0, 0, 0.1);
      &:hover,&:focus{
        background-color: #fff;
        border: 1px solid #20c77c !important;
      }
    }
  }

  .avatar {
    flex-shrink: 0;
    cursor: pointer;
  }
}

::v-deep .ant-scroll-number {
  transform: translate(82%, -100%);
  box-shadow: 0px 6px 15px 0px rgba(255, 44, 65, 0.2);
}

.search-input {
  width: 390px;
  background: none;
}

.menu-bg-btn {
  width: 48%;
  height: 50px;
  border: 1px solid #00B96B;
  color: #00B96B;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 6px;

  &.blk-btn {
    background-color: #000;
    color: #fff;
    border-color: #000;

    img {
      width: 24px;
    }
  }
}

@media screen and (max-width: 1580px) {
  .site-header {
    padding: 0px 30px;
  }

  .head-recharge-btn,
  .head-menu {
    margin-left: auto;
  }

  .search-input {
    width: 260px;
  }

  // ::v-deep .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
  //   width: 52px;
  //   padding: 0px 10px;
  //   white-space: initial;
  //   line-height: 20px;
  // }
}

.showSearchInput {
  display: block;
}

.hideSearchInput {
  display: none;
}

// @media screen and (max-width: 1200px) {
//   .showSearchInput {
//     display: none;
//   }

//   .hideSearchInput {
//     display: block;
//   }
// }
@media screen and (max-width: 1024px) {
  .site-header {
    padding: 0px 0px 0px 25px;
  }

  .head-menu {
    margin-left: 30px;

    // .ant-menu-horizontal {
    //   width: 300px;
    // }

  }

  .header-right .search-head {
    margin-right: 0px;
  }

  .head-recharge-btn {
    margin-left: 20px;
  }

  .search-input {
    width: 220px;
  }

}

.menu-btn {
  display: none;
}

@media screen and (max-width: 1200px) {
  .menu-btn {
    display: block;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }

  .head-menu,
  .head-recharge-btn,
  .header-right .search-head,
  .avatar {
    display: none;
  }

  .hideSearchInput {
    width: 24px;
    height: 24px;

    img {
      width: 100%;
    }

    display: block;
  }
}

.small-menu {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #fff;
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 999;
  padding: 20px;
}

::v-deep .ant-menu-inline {
  border: none;

  .ant-menu-item::after {
    right: auto;
    left: 0px;
  }
}

::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.list-head {
  display: flex;
  align-items: center;

  .small-avatar {
    width: 47px;
    height: 47px;
    border-radius: 50%;
  }

  .tag {
    border-radius: 4px;
    border: 1px solid #00B96B;
    width: max-content;
    padding: 2px 5px;
  }
}

.topup {
  background-color: #eee;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.top-ad {
  .ad-img {
    max-width: 100%;
    display: block;
    object-fit: cover;
    transition: 1s ease;
  }
}
</style>
